import iconsPackage from '@magalu/tom-icons/package.json';

const getIconsVersion = async (
  ctx,
  props: { data?: Record<string, unknown> },
  args: any
) => {
  props.data = {
    ...(props.data || {}),
    iconsVersion: iconsPackage.version,
  }
};

export default getIconsVersion;
