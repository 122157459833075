import { Ctx, useCookies } from '@magalu/rave/router';
import useServerEnv from '@magalu/rave/config/useServerEnv';

const getCartExtensions = async (
  ctx: Ctx,
  props: { data?: Record<string, unknown> },
  args: any
) => {
  const cookie = useCookies(ctx);
  const partnerId = cookie.getCookie('MLPARCEIRO') as string;
  const promoterId = cookie.getCookie('promoter_id') as string;
  const { server } = useServerEnv();
  const cartExtensions: Record<string, unknown>[] = [];

  if (promoterId && partnerId && partnerId === server?.promoter?.partnerId) {
    cartExtensions.push({
      name: 'magazinevoce',
      data: {
        showcase_id: promoterId,
      },
    });
  }

  props.data = {
    ...(props.data || {}),
    cartExtensions,
  };
};

export default getCartExtensions;
