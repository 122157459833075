import raveIf from '@magalu/rave/template/rave-if';
import raveExp from '@magalu/rave/template/rave-exp';
import raveReplace from '@magalu/rave/template/rave-replace';
import raveJoin from '@magalu/rave/template/rave-join';
import numberParser from '@magalu/rave/parser/number-parser';
import uppercaseParser from '@magalu/rave/parser/uppercase-parser';
import encodedObjectParser from '@magalu/rave/parser/encoded-object-parser';

export const getComponents = props => ({
    'header-preconnect-0': {
      name: `Preconnect`,
      namedImport: true,
      from: `@magalu/rave/components`,
      props: {
        list: [
          `https://www.googletagmanager.com`,
          `https://wx.mlcdn.com.br`,
          `https://6590300.fls.doubleclick.net`,
          `https://a-static.mlcdn.com.br`,
          `https://ad.doubleclick.net`,
          `https://adservice.google.com`,
          `https://analytics.google.com`,
          `https://analytics.pangle-ads.com`,
          `https://analytics.tiktok.com`,
          `https://analytics.twitter.com`,
          `https://bat.bing.com`,
          `https://c.bing.com`,
          `https://c.clarity.ms`,
          `https://cas.avalon.perfdrive.com`,
          `https://cdn.perfdrive.com`,
          `https://connect.facebook.net`,
          `https://ct.pinterest.com`,
          `https://d.clarity.ms`,
          `https://googleads.g.doubleclick.net`,
          `https://gum.criteo.com`,
          `https://i.btg360.com.br`,
          `https://s.pinimg.com`,
          `https://sc-static.net`,
          `https://script.hotjar.com`,
          `https://site-content.magazineluiza.com.br`,
          `https://static.ads-twitter.com`,
          `https://static.criteo.net`,
          `https://static.hotjar.com`,
          `https://t.co`,
          `https://tags.creativecdn.com`,
          `https://td.doubleclick.net`,
          `https://tom.mlcdn.com.br`,
          `https://tr.snapchat.com`,
          `https://tr6.snapchat.com`,
          `https://us.creativecdn.com`,
          `https://www.clarity.ms`,
          `https://www.facebook.com`,
          `https://www.google-analytics.com`,
          `https://www.google.com`,
          `https://www.google.com.br`,
          `https://dev.visualwebsiteoptimizer.com`,
        ],
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `header-preconnect-0`,
      placeholder: undefined,
    },
    'header-all-in-1': {
      name: `AllIn`,
      from: `@magalu/ecomm-components/rave/scripts/AllIn`,
      props: {
        loadInWebWorker: true,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `header-all-in-1`,
      placeholder: undefined,
    },
    'header-criteo-2': {
      name: `Criteo`,
      from: `@magalu/ecomm-components/rave/scripts/Criteo`,
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `header-criteo-2`,
      placeholder: undefined,
    },
    'header-bing-3': {
      name: `Bing`,
      from: `@magalu/ecomm-components/rave/scripts/Bing`,
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `header-bing-3`,
      placeholder: undefined,
    },
    'header-google-tag-manager-4': {
      name: `GoogleTagManager`,
      from: `@magalu/ecomm-components/rave/scripts/GoogleTagManager`,
      props: {
        id: `GTM-N9NRZNKX`,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `header-google-tag-manager-4`,
      placeholder: undefined,
    },
    'header-tik-tok-5': {
      name: `TikTok`,
      from: `@magalu/ecomm-components/rave/scripts/TikTok`,
      props: {
        id: `C1I87V1T0U322RQPSRKG`,
        loadInWebWorker: true,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `header-tik-tok-5`,
      placeholder: undefined,
    },
    'header-rtbhouse-6': {
      name: `RTBHouse`,
      from: `@magalu/ecomm-components/rave/scripts/RTBHouse`,
      props: {
        id: `msWukR4UlwKQouZSY8IT`,
        loadInWebWorker: true,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `header-rtbhouse-6`,
      placeholder: undefined,
    },
    'header-stewie-7': {
      name: `Stewie`,
      from: `@magalu/ecomm-components/rave/scripts/Stewie`,
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `header-stewie-7`,
      placeholder: undefined,
    },
    'header-spotify-ad-analytics-8': {
      name: `SpotifyAdAnalytics`,
      from: `@magalu/ecomm-components/rave/scripts/SpotifyAdAnalytics`,
      props: {
        id: `66f417c2d2344c0980e19d4e7f996073`,
        loadInWebWorker: true,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `header-spotify-ad-analytics-8`,
      placeholder: undefined,
    },
    'header-hotjar-9': {
      name: `Hotjar`,
      from: `@magalu/ecomm-components/rave/scripts/Hotjar`,
      props: {
        id: `4936838`,
        loadInWebWorker: false,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `header-hotjar-9`,
      placeholder: undefined,
    },
    'header-vwo-10': {
      name: `Vwo`,
      from: `@magalu/ecomm-components/rave/scripts/Vwo`,
      id: `header-vwo-10`,
      props: {
        id: `752930`,
        loadInWebWorker: false,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      placeholder: undefined,
    },
    'header-magalu-ads-11': {
      name: `MagaluAds`,
      from: `@magalu/ecomm-components/rave/scripts/MagaluAds`,
      props: {
        loadInWebWorker: true,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `header-magalu-ads-11`,
      placeholder: undefined,
    },
    'header-radware-12': {
      name: `Radware`,
      from: `@magalu/ecomm-components/rave/scripts/Radware`,
      props: {
        loadInWebWorker: true,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `header-radware-12`,
      placeholder: undefined,
    },
    'header-loader-13': {
      name: `Loader`,
      from: `@magalu/ecomm-components/rave/Loader`,
      csr: {
        strategy: `onActivityDetection`,
        props: {
        },
      },
      ssr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `header-loader-13`,
    },
    'header-state-loader-14': {
      name: `StateLoader`,
      from: `@magalu/ecomm-components/rave/StateLoader`,
      csr: {
        strategy: `onActivityDetection`,
        props: {
        },
      },
      ssr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `header-state-loader-14`,
    },
    'header-closeable-ad-unit-15': {
      name: `CloseableAdUnit`,
      from: `@magalu/ecomm-components/rave/CloseableAdUnit`,
      props: {
        bannerId: `hypertop`,
        adUnit: {
          path: `/21908074511/mobile/discovery_padrao/hypertop`,
          size: [
            329,
            64,
          ],
        },
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `header-closeable-ad-unit-15`,
      placeholder: undefined,
    },
    'header-header-16': {
      name: `Header`,
      from: `@magalu/ecomm-components/rave/Header`,
      props: {
        signOutUrl: `https://sacolamobile.magazineluiza.com.br/#/sair/?next=https%3A%2F%2Fm.magazineluiza.com.br%2F&origin=magazineluiza`,
        footerLabels: [
          `Compre pelo televendas`,
          `Seg. à Dom. (exceto feriados)`,
          `das 8h às 20h`,
        ],
        logoUrl: `/`,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `header-header-16`,
      placeholder: undefined,
    },
    'header-event-page-view-dispatcher-17': {
      name: `EventPageViewDispatcher`,
      from: `@magalu/ecomm-components/rave/EventPageViewDispatcher`,
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `header-event-page-view-dispatcher-17`,
      placeholder: undefined,
    },
    'header-loader-13-children-page-loading-0': {
      name: `PageLoading`,
      csr: {
        strategy: `onActivityDetection`,
        props: {
        },
      },
      from: `@magalu/ecomm-components/components/PageLoading`,
      ssr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `header-loader-13-children-page-loading-0`,
      placeholder: undefined,
    },
    'header-state-loader-14-children-page-loading-0': {
      name: `PageLoading`,
      csr: {
        strategy: `onActivityDetection`,
        props: {
        },
      },
      from: `@magalu/ecomm-components/components/PageLoading`,
      ssr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `header-state-loader-14-children-page-loading-0`,
      placeholder: undefined,
    },
    'content-json-ld-0': {
      name: `JsonLD`,
      from: `@magalu/ecomm-components/rave/scripts/JsonLD`,
      props: {
        type: `product-page`,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-json-ld-0`,
      placeholder: undefined,
    },
    'content-json-ld-1': {
      name: `JsonLD`,
      from: `@magalu/ecomm-components/rave/scripts/JsonLD`,
      props: {
        type: `faq`,
        content: {
          faq: raveIf([
            props?.data?.questions?.items[0],
            props?.data?.questions?.items,
            props?.data?.seoPdpContent?.faq,
          ]),
        },
      },
      show: raveExp({
        '||': [
          {
            '??': [
              props?.data?.seoPdpContent?.faq,
              null,
            ],
          },
          {
            '??': [
              props?.data?.questions?.items,
              null,
            ],
          },
        ],
      }),
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: `show`,
      spacing: {
      },
      id: `content-json-ld-1`,
      placeholder: undefined,
    },
    'content-zip-code-bar-2': {
      name: `ZipCodeBar`,
      from: `@magalu/ecomm-components/rave/ZipCodeBar`,
      ssr: false,
      csr: {
        strategy: `onPageLoading`,
        props: {
        },
      },
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-zip-code-bar-2`,
      placeholder: undefined,
    },
    'content-zip-code-modal-3': {
      name: `ZipCodeModal`,
      from: `@magalu/ecomm-components/rave/ZipCodeModal`,
      ssr: false,
      csr: {
        strategy: `onPageLoading`,
        props: {
        },
      },
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-zip-code-modal-3`,
      placeholder: undefined,
    },
    'content-row-4': {
      name: `Row`,
      from: `@magalu/ecomm-components/components/Row`,
      props: {
        className: `flex flex-col bg-surface-container-lowest`,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-row-4`,
    },
    'content-showcase-5': {
      name: `Showcase`,
      from: `@magalu/ecomm-components/rave/Showcase`,
      props: {
        pageId: `mAmrPHGlhj`,
        placeId: `RiGQ7RdPP0`,
        productId: props?.route?.query?.productId,
        className: `mx-md pb-md`,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-showcase-5`,
      placeholder: undefined,
    },
    'content-row-6': {
      name: `Row`,
      from: `@magalu/ecomm-components/components/Row`,
      props: {
        className: `flex flex-col bg-surface-container-lowest`,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-row-6`,
    },
    'content-row-7': {
      name: `Row`,
      from: `@magalu/ecomm-components/components/Row`,
      props: {
        className: `mt-md flex flex-col bg-surface-container-lowest`,
      },
      show: raveExp({
        '!==': [
          props?.data?.product?.subcategory?.id,
          `SEGR`,
        ],
      }),
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: `show`,
      spacing: {
      },
      id: `content-row-7`,
    },
    'content-row-8': {
      name: `Row`,
      from: `@magalu/ecomm-components/components/Row`,
      props: {
        className: `mt-md flex flex-col bg-surface-container-lowest`,
      },
      show: raveExp({
        '!==': [
          props?.data?.product?.subcategory?.id,
          `SEGR`,
        ],
      }),
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: `show`,
      spacing: {
      },
      id: `content-row-8`,
    },
    'content-row-9': {
      name: `Row`,
      from: `@magalu/ecomm-components/components/Row`,
      props: {
        className: `mt-md py-md flex flex-col bg-surface-container-lowest`,
      },
      show: raveExp({
        '===': [
          props?.data?.product?.subcategory?.id,
          `SEGR`,
        ],
      }),
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: `show`,
      spacing: {
      },
      id: `content-row-9`,
    },
    'content-row-10': {
      name: `Row`,
      from: `@magalu/ecomm-components/components/Row`,
      props: {
        className: `mt-md py-md flex flex-col bg-surface-container-lowest`,
      },
      show: raveExp({
        '!==': [
          props?.data?.product?.subcategory?.id,
          `SEGR`,
        ],
      }),
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: `show`,
      spacing: {
      },
      id: `content-row-10`,
    },
    'content-row-11': {
      name: `Row`,
      from: `@magalu/ecomm-components/components/Row`,
      props: {
        className: `mt-md py-md flex flex-col bg-surface-container-lowest`,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-row-11`,
    },
    'content-row-12': {
      name: `Row`,
      from: `@magalu/ecomm-components/components/Row`,
      props: {
        className: `mt-md pt-md flex flex-col bg-surface-container-lowest`,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-row-12`,
    },
    'content-row-13': {
      name: `Row`,
      from: `@magalu/ecomm-components/components/Row`,
      show: raveExp({
        '!==': [
          props?.data?.product?.seller?.category,
          `1p`,
        ],
      }),
      props: {
        className: `mt-md pt-md flex flex-col bg-surface-container-lowest`,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: `show`,
      spacing: {
      },
      id: `content-row-13`,
    },
    'content-showcase-14': {
      name: `Showcase`,
      csr: {
        strategy: `onLazyLoading`,
        props: {
        },
      },
      from: `@magalu/ecomm-components/rave/Showcase`,
      props: {
        pageId: `mAmrPHGlhj`,
        placeId: `RYmKwYF0uh`,
        productId: props?.route?.query?.productId,
        className: `mx-md pb-md`,
      },
      ssr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-showcase-14`,
      placeholder: undefined,
    },
    'content-ads-showcase-15': {
      name: `AdsShowcase`,
      csr: {
        strategy: `onLazyLoading`,
        props: {
        },
      },
      from: `@magalu/ecomm-components/rave/AdsShowcase`,
      props: {
        channelCode: `MSITE`,
        exhibition: `carousel`,
        limit: 6,
        placement: `desktopb2cbottom`,
        productId: props?.route?.query?.productId,
        showcase: {
          config: {
            title: `👍 Seleção de produtos patrocinados para você`,
          },
        },
        className: `px-md pb-md`,
      },
      ssr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-ads-showcase-15`,
      placeholder: undefined,
    },
    'content-magalu-ads-showcase-16': {
      name: `MagaluAdsShowcase`,
      from: `@magalu/ecomm-components/rave/MagaluAdsShowcase`,
      show: false,
      csr: {
        strategy: `onLazyLoading`,
        props: {
          temporary: {
            className: `min-h-[480px]`,
          },
        },
      },
      props: {
        channel: `MSITE`,
        publisherId: `magazineluiza`,
        exhibition: `carousel`,
        page: `SHOWCASE`,
        adsPlacement: `CAROUSEL`,
        slots: 6,
        placement: `desktopb2cbottom`,
        pathAds: `/sponsored/tags`,
        method: `GET`,
        showcase: {
          config: {
            title: `👍 Seleção de produtos patrocinados para você`,
          },
        },
        className: `px-md pb-md`,
      },
      ssr: false,
      hasSpacing: false,
      displayCondition: `show`,
      spacing: {
      },
      id: `content-magalu-ads-showcase-16`,
      placeholder: undefined,
    },
    'content-showcase-17': {
      name: `Showcase`,
      csr: {
        strategy: `onLazyLoading`,
        props: {
        },
      },
      from: `@magalu/ecomm-components/rave/Showcase`,
      props: {
        pageId: `mAmrPHGlhj`,
        placeId: `qugQi55lh4`,
        productId: props?.route?.query?.productId,
        className: `mx-md pb-md`,
      },
      ssr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-showcase-17`,
      placeholder: undefined,
    },
    'content-row-18': {
      name: `Row`,
      from: `@magalu/ecomm-components/components/Row`,
      props: {
        className: `mt-md pt-lg pb-md flex flex-col bg-surface-container-lowest`,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-row-18`,
    },
    'content-showcase-19': {
      name: `Showcase`,
      csr: {
        strategy: `onLazyLoading`,
        props: {
        },
      },
      from: `@magalu/ecomm-components/rave/Showcase`,
      props: {
        pageId: `mAmrPHGlhj`,
        placeId: `dnhhGeeru9`,
        productId: props?.route?.query?.productId,
        className: `mx-md pb-md`,
      },
      ssr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-showcase-19`,
      placeholder: undefined,
    },
    'content-row-20': {
      name: `Row`,
      from: `@magalu/ecomm-components/components/Row`,
      show: props?.data?.questions?.sellerInfo?.enabled,
      props: {
        className: `flex-col mt-md pb-md gap-sm px-md bg-surface-container-lowest`,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: `show`,
      spacing: {
      },
      id: `content-row-20`,
    },
    'content-showcase-21': {
      name: `Showcase`,
      csr: {
        strategy: `onLazyLoading`,
        props: {
        },
      },
      from: `@magalu/ecomm-components/rave/Showcase`,
      props: {
        pageId: `mAmrPHGlhj`,
        placeId: `jupMXmS6EV`,
        productId: props?.route?.query?.productId,
        className: `mx-md pb-md`,
      },
      ssr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-showcase-21`,
      placeholder: undefined,
    },
    'content-row-22': {
      name: `Row`,
      from: `@magalu/ecomm-components/components/Row`,
      show: props?.data?.seoPdpContent?.faq,
      props: {
        className: `mt-md py-md flex flex-col bg-surface-container-lowest`,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: `show`,
      spacing: {
      },
      id: `content-row-22`,
    },
    'content-lu-content-23': {
      name: `LuContent`,
      from: `@magalu/ecomm-components/rave/LuContent`,
      props: {
        className: `mx-md my-lg`,
        header: {
          title: props?.data?.product?.subcategory?.name,
        },
        body: {
          variation: `vertical`,
        },
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-lu-content-23`,
      placeholder: undefined,
    },
    'content-term-list-24': {
      name: `TermList`,
      from: `@magalu/ecomm-components/rave/TermList`,
      show: props?.data?.product?.terms,
      props: {
        titleAs: `h2`,
        title: `Links relacionados`,
        terms: props?.data?.product?.terms,
        typeList: `inline`,
        showAllItems: false,
        limitListItems: 10,
        className: `px-md py-lg bg-brand-darker text-on-brand-darker font-xsm-regular`,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: `show`,
      spacing: {
      },
      id: `content-term-list-24`,
      placeholder: undefined,
    },
    'content-gatekeeper-25': {
      name: `Gatekeeper`,
      csr: {
        strategy: `onActivityDetection`,
        props: {
        },
      },
      props: {
        waitTime: 5000,
        waitPointerTime: 1000,
        exhibitionLimit: 2,
        appUrl: `https://magazineluiza.onelink.me/589508454?af_xp=custom&pid=bannermsite&is_retargeting=true&c=bannermsite_porteirooficial&deep_link_value=`,
        appUrlDeepLink: `https://www.magazineluiza.com.br/${props?.data?.product?.path}?partner_id=74085&utm_source=banner&utm_medium=porteiro`,
      },
      ssr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-gatekeeper-25`,
      placeholder: undefined,
    },
    'content-row-4-children-breadcrumb-0': {
      name: `Breadcrumb`,
      from: `@magalu/ecomm-components/rave/Breadcrumb`,
      props: {
        pageId: `product`,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-row-4-children-breadcrumb-0`,
      placeholder: undefined,
    },
    'content-row-6-children-media-gallery-0': {
      name: `MediaGallery`,
      from: `@magalu/ecomm-components/rave/MediaGallery`,
      props: {
        preload: true,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-row-6-children-media-gallery-0`,
      placeholder: undefined,
    },
    'content-row-6-children-tag-1': {
      name: `Tag`,
      id: `content-row-6-children-tag-1`,
      from: `@magalu/ecomm-components/rave/Tag`,
      show: raveExp({
        '==': [
          props?.data?.product?.offerTags[0],
          `magalu_indica`,
        ],
      }),
      props: {
        className: `font-2xsm-bold mx-md mt-lg w-fit`,
        children: `magalu indica`,
        endIcon: `check-magalu-indica`,
        events: [
          {
            eventName: `product:label:click`,
            eventArgs: {
              value: {
                id: `recommendedproduct`,
                label: `magalu-indica`,
                variationId: props?.data?.product?.variationId,
              },
            },
          },
          {
            eventName: `recommendedproductmodal:toggle`,
          },
        ],
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: `show`,
      spacing: {
      },
      placeholder: undefined,
    },
    'content-row-6-children-heading-2': {
      name: `Heading`,
      from: `@magalu/ecomm-components/rave/Heading`,
      props: {
        as: `h1`,
        title: props?.data?.product?.title,
        publish: `product:title:click`,
        className: raveIf([
          raveExp({
            '==': [
              props?.data?.product?.offerTags[0],
              `magalu_indica`,
            ],
          }),
          `font-md-regular mx-md text-on-surface-2 mt-xsm`,
          `font-md-regular mx-md text-on-surface-2 mt-lg`,
        ]),
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-row-6-children-heading-2`,
      placeholder: undefined,
    },
    'content-row-6-children-row-3': {
      name: `Row`,
      from: `@magalu/ecomm-components/components/Row`,
      props: {
        className: `pl-sm pr-md pt-sm pb-xsm justify-between`,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-row-6-children-row-3`,
    },
    'content-row-6-children-row-3-children-rating-0': {
      name: `Rating`,
      from: `@magalu/ecomm-components/rave/Rating`,
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-row-6-children-row-3-children-rating-0`,
      placeholder: undefined,
    },
    'content-row-6-children-row-3-children-row-1': {
      name: `Row`,
      from: `@magalu/ecomm-components/components/Row`,
      props: {
        className: `items-center gap-xsm pl-xsm`,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-row-6-children-row-3-children-row-1`,
    },
    'content-row-6-children-row-3-children-row-1-children-social-share-0': {
      name: `SocialShare`,
      from: `@magalu/ecomm-components/rave/SocialShare`,
      props: {
        variation: `circle`,
        className: `pl-sm`,
        shareData: {
          title: props?.data?.product?.title,
          text: props?.data?.product?.title,
          url: `https://m.magazineluiza.com.br${props?.route?.asPath}?partner_id=64853&utm_source=pdp_desk&utm_medium=share`,
        },
        socialPlatforms: [
          {
            platform: `facebook`,
            url: `https://m.facebook.com/sharer/sharer.php?u={url}`,
            icon: `icon-facebook`,
          },
          {
            platform: `twitter`,
            url: `https://twitter.com/intent/tweet?text={title}&url={url}`,
            icon: `icon-twitter`,
          },
          {
            platform: `whatsapp`,
            url: `https://wa.me/?text={url}`,
            icon: `icon-whatsapp`,
          },
          {
            platform: `email`,
            url: `mailto:?subject={title}&body={desc}%20{url}`,
            icon: `icon-mail`,
          },
        ],
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-row-6-children-row-3-children-row-1-children-social-share-0`,
      placeholder: undefined,
    },
    'content-row-6-children-row-3-children-row-1-children-favorite-button-1': {
      name: `FavoriteButton`,
      from: `@magalu/ecomm-components/rave/FavoriteButton`,
      props: {
        variation: `circle`,
        name: props?.data?.product?.title,
        price: props?.data?.product?.price?.fullPrice,
        variationId: props?.data?.product?.variationId,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-row-6-children-row-3-children-row-1-children-favorite-button-1`,
      placeholder: undefined,
    },
    'content-row-6-children-attribute-selector-4': {
      name: `AttributeSelector`,
      from: `@magalu/ecomm-components/rave/AttributeSelector`,
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-row-6-children-attribute-selector-4`,
      placeholder: undefined,
    },
    'content-row-6-children-offer-timer-tag-5': {
      name: `OfferTimerTag`,
      id: `content-row-6-children-offer-timer-tag-5`,
      from: `@magalu/ecomm-components/rave/OfferTimerTag`,
      csr: {
        strategy: `onPageLoading`,
        props: {
        },
      },
      show: props?.route?.cookies?.toggle_offer_timer,
      props: {
        className: `mx-md mt-2xsm mb-xsm`,
      },
      ssr: false,
      hasSpacing: false,
      displayCondition: `show`,
      spacing: {
      },
      placeholder: undefined,
    },
    'content-row-6-children-sell-and-deliver-info-6': {
      name: `SellAndDeliverInfo`,
      from: `@magalu/ecomm-components/rave/SellAndDeliverInfo`,
      props: {
        className: `mx-md mt-sm mb-lg`,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-row-6-children-sell-and-deliver-info-6`,
      placeholder: undefined,
    },
    'content-row-6-children-seller-detail-7': {
      name: `SellerDetail`,
      from: `@magalu/ecomm-components/rave/SellerDetail`,
      props: {
        basePath: `/lojista/`,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-row-6-children-seller-detail-7`,
      placeholder: undefined,
    },
    'content-row-7-children-product-price-0': {
      name: `ProductPrice`,
      from: `@magalu/ecomm-components/rave/ProductPrice`,
      props: {
        className: `flex px-md pt-lg w-full justify-between`,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-row-7-children-product-price-0`,
      placeholder: undefined,
    },
    'content-row-7-children-product-tags-1': {
      name: `ProductTags`,
      from: `@magalu/ecomm-components/rave/ProductTags`,
      props: {
        tags: [
          `coupon-v1`,
          `minimum-quantity`,
          `international-purchase`,
        ],
        className: `mx-md w-fit`,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-row-7-children-product-tags-1`,
      placeholder: undefined,
    },
    'content-row-7-children-import-taxes-2': {
      name: `ImportTaxes`,
      from: `@magalu/ecomm-components/rave/ImportTaxes`,
      show: raveExp({
        '&&': [
          {
            '??': [
              props?.data?.product?.price?.currency,
              null,
            ],
          },
          {
            '!==': [
              props?.data?.product?.price?.currency,
              `BRL`,
            ],
          },
        ],
      }),
      csr: {
        strategy: `onLazyLoading`,
        props: {
        },
      },
      props: {
        className: `mx-md mt-xsm`,
      },
      ssr: false,
      hasSpacing: false,
      displayCondition: `show`,
      spacing: {
      },
      id: `content-row-7-children-import-taxes-2`,
      placeholder: undefined,
    },
    'content-row-7-children-payment-method-cards-3': {
      name: `PaymentMethodCards`,
      from: `@magalu/ecomm-components/rave/PaymentMethodCards`,
      show: raveExp({
        '||': [
          {
            '??': [
              props?.data?.product?.paymentMethods,
              null,
            ],
          },
          {
            '??': [
              props?.data?.product?.installment,
              null,
            ],
          },
        ],
      }),
      props: {
        className: `pb-sm mb-sm mt-md gap-md`,
        disabledMethods: [
          `account_balance`,
        ],
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: `show`,
      spacing: {
      },
      id: `content-row-7-children-payment-method-cards-3`,
      placeholder: undefined,
    },
    'content-row-7-children-tab-installments-4': {
      name: `TabInstallments`,
      from: `@magalu/ecomm-components/rave/TabInstallments`,
      show: raveExp({
        '||': [
          {
            '??': [
              props?.data?.product?.paymentMethods,
              null,
            ],
          },
          {
            '??': [
              props?.data?.product?.installment,
              null,
            ],
          },
        ],
      }),
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: `show`,
      spacing: {
      },
      id: `content-row-7-children-tab-installments-4`,
      placeholder: undefined,
    },
    'content-row-8-children-shipping-0': {
      name: `Shipping`,
      from: `@magalu/ecomm-components/rave/Shipping`,
      show: props?.data?.product?.available,
      csr: {
        strategy: `onLazyLoading`,
        props: {
        },
      },
      props: {
        actionLabel: `calcular`,
        changeLabel: `alterar`,
        maxCityLength: 24,
        placeholder: `Calcular frete e prazo`,
        salesChannelId: `9`,
        organizationId: `magazine_luiza`,
        className: `p-md`,
      },
      ssr: false,
      hasSpacing: false,
      displayCondition: `show`,
      spacing: {
      },
      id: `content-row-8-children-shipping-0`,
      placeholder: undefined,
    },
    'content-row-8-children-let-me-know-1': {
      name: `LetMeKnow`,
      from: `@magalu/ecomm-components/rave/LetMeKnow`,
      hide: props?.data?.product?.available,
      props: {
        origin: `pdp-mobile`,
        success: `Cadastro realizado com sucesso. Em breve, você receberá um e-mail de confirmação.`,
        className: `px-md mb-sm`,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: `hide`,
      spacing: {
      },
      id: `content-row-8-children-let-me-know-1`,
      placeholder: undefined,
    },
    'content-row-9-children-services-buy-button-0': {
      name: `ServicesBuyButton`,
      from: `@magalu/ecomm-components/rave/ServicesBuyButton`,
      props: {
        label: `Ver produtos e preços`,
        servicesPageLink: `https://m.magazineluiza.com.br/servico/pedidos-elegiveis/${props?.data?.product?.id}`,
        className: `px-md`,
        cartUrl: {
          default: `https://sacolamobile.magazineluiza.com.br/#/endereco`,
          login: `https://sacolamobile.magazineluiza.com.br/#/cliente/login`,
        },
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-row-9-children-services-buy-button-0`,
      placeholder: undefined,
    },
    'content-row-10-children-buy-buttons-0': {
      name: `BuyButtons`,
      from: `@magalu/ecomm-components/rave/BuyButtons`,
      props: {
        bagButton: {
          icon: `shopping-bag`,
          label: `Adicionar à sacola`,
        },
        buyButton: {
          outline: true,
          label: `Comprar agora`,
        },
        storeButton: {
          label: `Retire na loja grátis!`,
          secondary: ``,
        },
        servicesPageLink: `https://m.magazineluiza.com.br/produto/seguros/${props?.data?.product?.variationId}/`,
        channel: `MSITE`,
        cartUrl: {
          default: `https://sacolamobile.magazineluiza.com.br/#/endereco`,
          addToBag: `https://sacolamobile.magazineluiza.com.br/#`,
          floatButton: `https://sacolamobile.magazineluiza.com.br/#`,
          pickupStore: `https://sacolamobile.magazineluiza.com.br/#/endereco?tipo=retira-loja`,
          login: `https://sacolamobile.magazineluiza.com.br/#/cliente/login`,
        },
        className: `px-md`,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-row-10-children-buy-buttons-0`,
      placeholder: undefined,
    },
    'content-row-10-children-alert-1': {
      name: `Alert`,
      from: `@magalu/ecomm-components/components/Alert`,
      props: {
        as: `p`,
        className: `mx-md mt-md text-wrap`,
        message: raveExp({
          '||': [
            raveIf([
              raveExp({
                '==': [
                  props?.data?.product?.category?.id,
                  `MO`,
                ],
              }),
              `A compra do móvel não inclui serviço de montagem.`,
            ]),
            raveIf([
              raveExp({
                IN: [
                  [
                    `ARAR`,
                    `ARCA`,
                    `ARDT`,
                    `ACIV`,
                    `ARJA`,
                    `ARMS`,
                    `ARTT`,
                    `ARPR`,
                    `ARSP`,
                  ],
                  props?.data?.product?.subcategory?.id,
                ],
              }),
              `Antes da compra, confirmar com um técnico a voltagem do local de instalação, que pode ser 110V, 220V ou 380V.`,
            ]),
            raveIf([
              raveExp({
                IN: [
                  [
                    `ALCM`,
                    `CHAI`,
                    `MODI`,
                    `MPOL`,
                    `CJDP`,
                    `PDAM`,
                    `PNCP`,
                    `POLT`,
                    `POLR`,
                    `PLTI`,
                    `COES`,
                    `MOSO`,
                    `ESTO`,
                    `SA03`,
                    `SO4O`,
                    `SF5L`,
                    `SF6L`,
                    `MOSC`,
                    `BICA`,
                    `PDAM`,
                  ],
                  props?.data?.product?.subcategory?.id,
                ],
              }),
              `A tonalidade da cor do tecido pode variar de acordo com o lote do fabricante.`,
            ]),
            raveIf([
              raveExp({
                '==': [
                  props?.data?.product?.category?.id,
                  `BS`,
                ],
              }),
              `Atenção: produto para maiores de 18 anos.`,
            ]),
            raveIf([
              raveExp({
                IN: [
                  [
                    `BELC`,
                    `CERI`,
                    `CPLL`,
                    `CPTL`,
                    `FALA`,
                    `LTFM`,
                    `LEPO`,
                    `PPNH`,
                  ],
                  props?.data?.product?.subcategory?.id,
                ],
              }),
              `O MINISTÉRIO DA SAÚDE INFORMA:\n\nO aleitamento materno evita infecções e alergias e é recomendado até os 2 (dois) anos de idade ou mais.\n\nApós os 6 (seis) meses de idade, continue amamentando seu filho e ofereça novos alimentos.`,
            ]),
            ``,
          ],
        }),
        intent: raveIf([
          raveExp({
            IN: [
              [
                `ALCM`,
                `CHAI`,
                `MODI`,
                `MPOL`,
                `CJDP`,
                `PDAM`,
                `PNCP`,
                `POLT`,
                `POLR`,
                `PLTI`,
                `COES`,
                `MOSO`,
                `ESTO`,
                `SA03`,
                `SO4O`,
                `SF5L`,
                `SF6L`,
                `MOSC`,
                `BICA`,
                `PDAM`,
                `MO`,
                `ARAR`,
                `ARCA`,
                `ARDT`,
                `ACIV`,
                `ARJA`,
                `ARMS`,
                `ARTT`,
                `ARPR`,
                `ARSP`,
              ],
              props?.data?.product?.subcategory?.id,
            ],
          }),
          `info`,
          `warning`,
        ]),
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-row-10-children-alert-1`,
      placeholder: undefined,
    },
    'content-row-11-children-benefits-0': {
      name: `Benefits`,
      from: `@magalu/ecomm-components/rave/Benefits`,
      props: {
        direction: `vertical`,
        className: `mx-md`,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-row-11-children-benefits-0`,
      placeholder: undefined,
    },
    'content-row-11-children-report-button-1': {
      name: `ReportButton`,
      from: `@magalu/ecomm-components/components/ReportButton`,
      show: raveExp({
        '!==': [
          props?.data?.product?.seller?.id,
          `magazineluiza`,
        ],
      }),
      props: {
        children: `Denunciar Anúncio`,
        href: `/denuncie/${props?.data?.product?.id}/?from=${props?.route?.asPath}`,
        className: `mt-lg mx-md`,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: `show`,
      spacing: {
      },
      id: `content-row-11-children-report-button-1`,
      placeholder: undefined,
    },
    'content-row-12-children-buy-box-label-0': {
      name: `BuyBoxLabel`,
      from: `@magalu/ecomm-components/rave/BuyBoxLabel`,
      show: props?.data?.product?.isBuyBox,
      props: {
        path: props?.data?.product?.path,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: `show`,
      spacing: {
      },
      id: `content-row-12-children-buy-box-label-0`,
      placeholder: undefined,
    },
    'content-row-12-children-tab-product-detail-1': {
      name: `TabProductDetail`,
      from: `@magalu/ecomm-components/rave/TabProductDetail`,
      props: {
        label: `Informações do Produto`,
        detailTitle: `Detalhes do Produto`,
        className: `px-md`,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-row-12-children-tab-product-detail-1`,
      placeholder: undefined,
    },
    'content-row-13-children-seller-info-0': {
      name: `SellerInfo`,
      from: `@magalu/ecomm-components/rave/SellerInfo`,
      props: {
        className: `mx-md`,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-row-13-children-seller-info-0`,
      placeholder: undefined,
    },
    'content-row-18-children-empty-review-button-0': {
      name: `EmptyReviewButton`,
      from: `@magalu/ecomm-components/rave/EmptyReviewButton`,
      props: {
        title: `Seja o primeiro a avaliar esse produto`,
        subtitle: `Esse produto ainda não tem avaliação`,
        className: `mx-xlg pb-lg pt-lg`,
        button: {
          label: `Avaliar o produto`,
          as: `a`,
          variant: `outline`,
          className: `font-sm-medium`,
          size: `large`,
          href: raveReplace([
            `/${props?.data?.product?.url}`,
            `/p`,
            `/r`,
          ]),
        },
      },
      hide: props?.data?.productRating?.userReviews?.items,
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: `hide`,
      spacing: {
      },
      id: `content-row-18-children-empty-review-button-0`,
      placeholder: undefined,
    },
    'content-row-18-children-row-1': {
      name: `Row`,
      from: `@magalu/ecomm-components/components/Row`,
      show: props?.data?.productRating?.userReviews?.items,
      props: {
        className: `flex-col gap-xlg px-md mb-md`,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: `show`,
      spacing: {
      },
      id: `content-row-18-children-row-1`,
    },
    'content-row-18-children-row-1-children-heading-0': {
      name: `Heading`,
      from: `@magalu/ecomm-components/rave/Heading`,
      props: {
        title: `Avaliações dos clientes`,
        as: `h2`,
        className: `font-md-bold`,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-row-18-children-row-1-children-heading-0`,
      placeholder: undefined,
    },
    'content-row-18-children-row-1-children-review-stats-1': {
      name: `ReviewStats`,
      from: `@magalu/ecomm-components/rave/ReviewStats`,
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-row-18-children-row-1-children-review-stats-1`,
      placeholder: undefined,
    },
    'content-row-18-children-row-1-children-review-listing-2': {
      name: `ReviewListing`,
      from: `@magalu/ecomm-components/rave/ReviewListing`,
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-row-18-children-row-1-children-review-listing-2`,
      placeholder: undefined,
    },
    'content-row-18-children-row-1-children-button-3': {
      name: `Button`,
      from: `@magalu/ecomm-components/rave/Button`,
      props: {
        pushToSiteDomain: true,
        href: `/review/${props?.route?.query?.productId}/${props?.route?.query?.slug}/${props?.route?.query?.categoryId}/${props?.route?.query?.subCategoryId}`,
        label: `Ver todas as avaliações`,
        as: `a`,
        variant: `outline`,
        className: `font-sm-medium`,
        size: `large`,
        full: true,
        eventButton: `reviewpdp:button:click`,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-row-18-children-row-1-children-button-3`,
      placeholder: undefined,
    },
    'content-row-20-children-questions-list-0': {
      name: `QuestionsList`,
      from: `@magalu/ecomm-components/rave/QuestionsList`,
      props: {
        emptyPhrase: `Nenhuma pergunta foi feita ainda. Seja o primeiro!`,
        title: `Perguntas e Respostas`,
        className: `mt-lg`,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-row-20-children-questions-list-0`,
      placeholder: undefined,
    },
    'content-row-20-children-question-form-1': {
      name: `QuestionForm`,
      from: `@magalu/ecomm-components/rave/QuestionForm`,
      props: {
        label: `Tire sua dúvida com a loja sobre o produto`,
        placeholder: `Escreva aqui sua dúvida...`,
        totalQuestions: props?.data?.questions?.totalQuestions,
        questionsLimit: 3,
        buttonText: {
          authenticated: `Enviar pergunta`,
          unauthenticated: `Fazer uma pergunta`,
        },
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-row-20-children-question-form-1`,
      placeholder: undefined,
    },
    'content-row-20-children-question-confirm-2': {
      name: `QuestionConfirm`,
      from: `@magalu/ecomm-components/rave/QuestionConfirm`,
      csr: {
        strategy: `onActivityDetection`,
        props: {
        },
      },
      ssr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-row-20-children-question-confirm-2`,
      placeholder: undefined,
    },
    'content-row-20-children-question-feedback-3': {
      name: `QuestionFeedback`,
      from: `@magalu/ecomm-components/rave/QuestionFeedback`,
      csr: {
        strategy: `onActivityDetection`,
        props: {
        },
      },
      ssr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `content-row-20-children-question-feedback-3`,
      placeholder: undefined,
    },
    'content-row-20-children-button-4': {
      name: `Button`,
      from: `@magalu/ecomm-components/rave/Button`,
      show: raveExp({
        '>': [
          props?.data?.questions?.totalQuestions,
          3,
        ],
      }),
      props: {
        pushToSiteDomain: true,
        href: `/q/a/${props?.route?.query?.productId}/${props?.route?.query?.slug}/${props?.route?.query?.categoryId}/${props?.route?.query?.subCategoryId}`,
        label: `Ver mais perguntas`,
        as: `a`,
        variant: `outline`,
        className: `font-sm-medium`,
        size: `medium`,
        full: true,
        eventButton: `question-form:see-more`,
        eventValue: {
          label: `Ver mais perguntas`,
          productId: props?.route?.query?.productId,
          totalQuestions: props?.data?.questions?.totalQuestions,
        },
        'data-testid': `see-more-questions`,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: `show`,
      spacing: {
      },
      id: `content-row-20-children-button-4`,
      placeholder: undefined,
    },
    'content-row-22-children-faq-0': {
      name: `Faq`,
      from: `@magalu/ecomm-components/rave/Faq`,
      show: props?.data?.seoPdpContent?.faq,
      props: {
        className: `px-md`,
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: `show`,
      spacing: {
      },
      id: `content-row-22-children-faq-0`,
      placeholder: undefined,
    },
    'footer-back-to-top-0': {
      name: `BackToTop`,
      from: `@magalu/ecomm-components/components/BackToTop`,
      csr: {
        strategy: `onActivityDetection`,
        props: {
        },
      },
      ssr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `footer-back-to-top-0`,
      placeholder: undefined,
    },
    'footer-footer-1': {
      name: `Footer`,
      from: `@magalu/ecomm-components/components/Footer`,
      props: {
        as: `div`,
        data: [
          `Preços e condições de pagamento exclusivos para compras via internet, podendo variar nas lojas físicas. Ofertas válidas na compra de até 5 peças de cada produto por cliente, até o término dos nossos estoques para internet. Caso os produtos apresentem divergências de valores, o preço válido é o da sacola de compras. Vendas sujeitas a análise e confirmação de dados.`,
          {
            text: `Política de privacidade`,
            href: `https://especiais.magazineluiza.com.br/politica-de-privacidade/`,
            rel: `noopener`,
          },
          [
            `Magazine Luiza S/A - CNPJ: 47.960.950/1088-36`,
            `Rua Arnulfo de Lima, 2385 - Vila Santa Cruz`,
            `CEP: 14.403-471 - Franca/SP`,
            `Endereço eletrônico: www.magazineluiza.com.br`,
            `www.magazineluiza.com.br/formulariocontato`,
            `® Magazine Luiza – Todos os direitos reservados`,
          ],
        ],
      },
      ssr: true,
      csr: false,
      hasSpacing: false,
      displayCondition: null,
      spacing: {
      },
      id: `footer-footer-1`,
      placeholder: undefined,
    },
  });

export const dataSource = {
  "graphql": {
    "configsDir": "config",
    "serverDefaultOptions": {
      "query": {
        "errorPolicy": "all",
        "fetchPolicy": "network-only"
      }
    }
  }
}

export const getHeadProps = (props) => ({
    links: [
      {
        rel: `preconnect`,
        href: `https://tom.mlcdn.com.br`,
      },
      {
        rel: `shortcut icon`,
        href: `https://site-content.magazineluiza.com.br/static/img/default/favicon-cc4cf323.png`,
      },
      {
        rel: `shortcut icon`,
        type: `image/ico`,
        href: `https://site-content.magazineluiza.com.br/static/img/default/favicon-cc4cf323.png`,
      },
      {
        preload: true,
        as: `font`,
        type: `font/woff2`,
        href: `https://tom.mlcdn.com.br/fonts/magalu-ui-variable/files/MagaluUIVariable.woff2`,
        crossOrigin: ``,
      },
      {
        preload: true,
        as: `font`,
        type: `font/truetype`,
        fetchpriority: `high`,
        href: `https://tom.mlcdn.com.br/icons/${props?.data?.iconsVersion}/font/tom-icons.ttf`,
        crossOrigin: ``,
      },
      {
        preload: true,
        as: `font`,
        type: `font/woff`,
        fetchpriority: `high`,
        href: `https://tom.mlcdn.com.br/icons/${props?.data?.iconsVersion}/font/tom-icons.woff`,
        crossOrigin: ``,
      },
      {
        preload: true,
        as: `font`,
        type: `font/woff2`,
        fetchpriority: `high`,
        href: `https://tom.mlcdn.com.br/icons/${props?.data?.iconsVersion}/font/tom-icons.woff2`,
        crossOrigin: ``,
      },
      {
        rel: `canonical`,
        href: raveExp({
          '??': [
            props?.data?.seoPdpContent?.meta?.canonical,
            `https://www.magazineluiza.com.br/${props?.data?.product?.path}`,
          ],
        }),
      },
    ],
    title: raveJoin([
      [
        raveExp({
          '??': [
            props?.data?.seoPdpContent?.meta?.title,
            props?.data?.product?.title,
          ],
        }),
        raveIf([
          props?.data?.product?.reference,
          ` - ${props?.data?.product?.reference}`,
        ]),
        ` - ${props?.data?.product?.subcategory?.name}`,
        ` - Magazine Luiza`,
      ],
    ]),
    meta: [
      {
        name: `description`,
        content: raveJoin([
          [
            raveExp({
              '??': [
                props?.data?.seoPdpContent?.meta?.description,
                props?.data?.product?.title,
              ],
            }),
            ` com as melhores condições você encontra no site do Magalu. Confira!`,
          ],
        ]),
      },
      {
        name: `keywords`,
        content: `${props?.data?.product?.title}, ${props?.data?.product?.category?.name} em oferta, ${props?.data?.product?.category?.name} baratos, promoção de ${props?.data?.product?.category?.name}, ${props?.data?.product?.subcategory?.name} em oferta, ${props?.data?.product?.subcategory?.name} baratos, promoção de ${props?.data?.product?.subcategory?.name}`,
      },
      {
        property: `og:title`,
        content: raveJoin([
          [
            raveExp({
              '??': [
                props?.data?.seoPdpContent?.meta?.title,
                props?.data?.product?.title,
              ],
            }),
            raveIf([
              props?.data?.product?.reference,
              ` - ${props?.data?.product?.reference}`,
            ]),
            ` - ${props?.data?.product?.subcategory?.name}`,
            ` - Magazine Luiza`,
          ],
        ]),
      },
      {
        property: `og:description`,
        content: raveJoin([
          [
            raveExp({
              '??': [
                props?.data?.seoPdpContent?.meta?.title,
                props?.data?.product?.title,
              ],
            }),
            ` com as melhores condições você encontra no site do Magalu. Confira!`,
          ],
        ]),
      },
      {
        property: `og:image`,
        content: raveReplace([
          raveReplace([
            props?.data?.product?.image,
            `{w}`,
            `470`,
          ]),
          `{h}`,
          `352`,
        ]),
      },
      {
        property: `og:type`,
        content: `website`,
      },
      {
        property: `og:site_name`,
        content: `Magazine Luiza`,
      },
      {
        property: `og:url`,
        content: `https://www.magazineluiza.com.br/${props?.data?.product?.path}`,
      },
    ],
  });

export const componentsList = [
  "Preconnect",
  "AllIn",
  "Criteo",
  "Bing",
  "GoogleTagManager",
  "TikTok",
  "RTBHouse",
  "Stewie",
  "SpotifyAdAnalytics",
  "Hotjar",
  "Vwo",
  "MagaluAds",
  "Radware",
  "Loader",
  "StateLoader",
  "CloseableAdUnit",
  "Header",
  "EventPageViewDispatcher",
  "PageLoading",
  "JsonLD",
  "ZipCodeBar",
  "ZipCodeModal",
  "Row",
  "Showcase",
  "AdsShowcase",
  "MagaluAdsShowcase",
  "LuContent",
  "TermList",
  "Gatekeeper",
  "Breadcrumb",
  "MediaGallery",
  "Tag",
  "Heading",
  "Rating",
  "SocialShare",
  "FavoriteButton",
  "AttributeSelector",
  "OfferTimerTag",
  "SellAndDeliverInfo",
  "SellerDetail",
  "ProductPrice",
  "ProductTags",
  "ImportTaxes",
  "PaymentMethodCards",
  "TabInstallments",
  "Shipping",
  "LetMeKnow",
  "ServicesBuyButton",
  "BuyButtons",
  "Alert",
  "Benefits",
  "ReportButton",
  "BuyBoxLabel",
  "TabProductDetail",
  "SellerInfo",
  "EmptyReviewButton",
  "ReviewStats",
  "ReviewListing",
  "Button",
  "QuestionsList",
  "QuestionForm",
  "QuestionConfirm",
  "QuestionFeedback",
  "Faq",
  "BackToTop",
  "Footer"
];

export const routeInfo = {
  id: 'product',
  name: 'produto',
  pathPattern: '/:slug/p/:productId/:categoryId/:subCategoryId/',
  pathAliases: {
  "path0": "slug",
  "path2": "productId",
  "path3": "categoryId",
  "path4": "subCategoryId"
},
  parser: {
    query: {
      "page": numberParser(),
      "categoryId": uppercaseParser(),
      "subCategoryId": uppercaseParser(),
    },
    cookie: {
      "ml2_sid_c": encodedObjectParser(),
    },
  },
  renderMethod: 'SSR',
  forwardCookies: '',
  forwardHeaders: 'theme'
};

export const manifest = {
  "useTypescript": true
};

export const getGlobalClassName = props => ([
    `bg-surface-container-lower`,
    raveExp({
      '??': [
        props?.route?.headers?.theme,
        ``,
      ],
    }),
  ]).join(' ').trim();

export const getPageVariables = props => ({
    product_productId: props?.route?.query?.productId,
    product_includeTerms: true,
    product_includeDescription: true,
    product_showPayment: true,
    product_termsSize: 4,
    productRating_variationId: props?.route?.query?.productId,
    productRating_includeUserReviews: true,
    breadcrumbQuery_categoryId: props?.route?.query?.categoryId,
    breadcrumbQuery_subCategoryId: props?.route?.query?.subCategoryId,
    seoPdpContent_productId: props?.route?.query?.productId,
    blogPostsByEcomm_categoryId: props?.route?.query?.categoryId,
    blogPostsByEcomm_subCategoryId: props?.route?.query?.subCategoryId,
    blogPostsByEcomm_blogPostQuantity: 3,
    questions_org: `vertical-seller`,
    questions_itemsPerPage: 10,
    questions_questionVariationId: props?.route?.query?.productId,
  });

export const middlewaresArgs = ({ 
      "getIconsVersion": (props) => ({
  }),
      "foundError": (props) => ({
    checkErrors: [
      `ProductErrorFromCatalog`,
      {
        service: `beehive`,
      },
    ],
  }),
      "foundCheck": (props) => ({
    requiredFields: [
      `product.id`,
    ],
  }),
      "productAttributeMapper": (props) => ({
  }),
      "redirectFromCanonicalPath": (props) => ({
    canonicalPath: props?.data?.product?.path,
  }),
      "getCartExtensions": (props) => ({
  }),
 });

export const config = {
  assetPrefix: 'https://wx.mlcdn.com.br/mixer-web/static/v1.58.0',
};
