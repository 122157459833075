// @ts-nocheck
import React from 'react';
import dynamic from 'next/dynamic';
import HeadNext from 'next/head';
import NextDefaultError from 'next/error';
import useServerEnv from '@magalu/rave/config/useServerEnv';
import { client  } from '@magalu/rave/graphql';
import { parseHeaders, shouldRender, middlewareClientExecutor, middlewareServerExecutor } from '@magalu/rave/utils';

import { ErrorBoundary } from '@magalu/rave/errorBoundary';

import { Head } from '@magalu/rave/components';
import { LazyLoading } from '@magalu/rave/components';
import { ActivityDetection } from '@magalu/rave/components';

// Modules
import { Preconnect } from '@magalu/rave/components';
import AllIn from '@magalu/ecomm-components/rave/scripts/AllIn';
import Criteo from '@magalu/ecomm-components/rave/scripts/Criteo';
import Bing from '@magalu/ecomm-components/rave/scripts/Bing';
import GoogleTagManager from '@magalu/ecomm-components/rave/scripts/GoogleTagManager';
import TikTok from '@magalu/ecomm-components/rave/scripts/TikTok';
import RTBHouse from '@magalu/ecomm-components/rave/scripts/RTBHouse';
import Stewie from '@magalu/ecomm-components/rave/scripts/Stewie';
import SpotifyAdAnalytics from '@magalu/ecomm-components/rave/scripts/SpotifyAdAnalytics';
import Hotjar from '@magalu/ecomm-components/rave/scripts/Hotjar';
import Vwo from '@magalu/ecomm-components/rave/scripts/Vwo';
import MagaluAds from '@magalu/ecomm-components/rave/scripts/MagaluAds';
import Radware from '@magalu/ecomm-components/rave/scripts/Radware';
  /**
  * for client modules in typescript, need to use dynamic<T> as any
  * read: https://github.com/vercel/next.js/issues/4515
  */
const Loader = dynamic<any>(() =>
  import('@magalu/ecomm-components/rave/Loader'), {
  ssr: false
});
  /**
  * for client modules in typescript, need to use dynamic<T> as any
  * read: https://github.com/vercel/next.js/issues/4515
  */
const StateLoader = dynamic<any>(() =>
  import('@magalu/ecomm-components/rave/StateLoader'), {
  ssr: false
});
import CloseableAdUnit from '@magalu/ecomm-components/rave/CloseableAdUnit';
import Header from '@magalu/ecomm-components/rave/Header';
import EventPageViewDispatcher from '@magalu/ecomm-components/rave/EventPageViewDispatcher';
  /**
  * for client modules in typescript, need to use dynamic<T> as any
  * read: https://github.com/vercel/next.js/issues/4515
  */
const PageLoading = dynamic<any>(() =>
  import('@magalu/ecomm-components/components/PageLoading'), {
  ssr: false
});
import JsonLD from '@magalu/ecomm-components/rave/scripts/JsonLD';
  /**
  * for client modules in typescript, need to use dynamic<T> as any
  * read: https://github.com/vercel/next.js/issues/4515
  */
const ZipCodeBar = dynamic<any>(() =>
  import('@magalu/ecomm-components/rave/ZipCodeBar'), {
  ssr: false
});
  /**
  * for client modules in typescript, need to use dynamic<T> as any
  * read: https://github.com/vercel/next.js/issues/4515
  */
const ZipCodeModal = dynamic<any>(() =>
  import('@magalu/ecomm-components/rave/ZipCodeModal'), {
  ssr: false
});
import Row from '@magalu/ecomm-components/components/Row';
import Showcase from '@magalu/ecomm-components/rave/Showcase';
  /**
  * for client modules in typescript, need to use dynamic<T> as any
  * read: https://github.com/vercel/next.js/issues/4515
  */
const AdsShowcase = dynamic<any>(() =>
  import('@magalu/ecomm-components/rave/AdsShowcase'), {
  ssr: false
});
  /**
  * for client modules in typescript, need to use dynamic<T> as any
  * read: https://github.com/vercel/next.js/issues/4515
  */
const MagaluAdsShowcase = dynamic<any>(() =>
  import('@magalu/ecomm-components/rave/MagaluAdsShowcase'), {
  ssr: false
});
import LuContent from '@magalu/ecomm-components/rave/LuContent';
import TermList from '@magalu/ecomm-components/rave/TermList';
  /**
  * for client modules in typescript, need to use dynamic<T> as any
  * read: https://github.com/vercel/next.js/issues/4515
  */
const Gatekeeper = dynamic<any>(() =>
  import('../../modules/Gatekeeper'), {
  ssr: false
});
import Breadcrumb from '@magalu/ecomm-components/rave/Breadcrumb';
import MediaGallery from '@magalu/ecomm-components/rave/MediaGallery';
import Tag from '@magalu/ecomm-components/rave/Tag';
import Heading from '@magalu/ecomm-components/rave/Heading';
import Rating from '@magalu/ecomm-components/rave/Rating';
import SocialShare from '@magalu/ecomm-components/rave/SocialShare';
import FavoriteButton from '@magalu/ecomm-components/rave/FavoriteButton';
import AttributeSelector from '@magalu/ecomm-components/rave/AttributeSelector';
  /**
  * for client modules in typescript, need to use dynamic<T> as any
  * read: https://github.com/vercel/next.js/issues/4515
  */
const OfferTimerTag = dynamic<any>(() =>
  import('@magalu/ecomm-components/rave/OfferTimerTag'), {
  ssr: false
});
import SellAndDeliverInfo from '@magalu/ecomm-components/rave/SellAndDeliverInfo';
import SellerDetail from '@magalu/ecomm-components/rave/SellerDetail';
import ProductPrice from '@magalu/ecomm-components/rave/ProductPrice';
import ProductTags from '@magalu/ecomm-components/rave/ProductTags';
  /**
  * for client modules in typescript, need to use dynamic<T> as any
  * read: https://github.com/vercel/next.js/issues/4515
  */
const ImportTaxes = dynamic<any>(() =>
  import('@magalu/ecomm-components/rave/ImportTaxes'), {
  ssr: false
});
import PaymentMethodCards from '@magalu/ecomm-components/rave/PaymentMethodCards';
import TabInstallments from '@magalu/ecomm-components/rave/TabInstallments';
  /**
  * for client modules in typescript, need to use dynamic<T> as any
  * read: https://github.com/vercel/next.js/issues/4515
  */
const Shipping = dynamic<any>(() =>
  import('@magalu/ecomm-components/rave/Shipping'), {
  ssr: false
});
import LetMeKnow from '@magalu/ecomm-components/rave/LetMeKnow';
import ServicesBuyButton from '@magalu/ecomm-components/rave/ServicesBuyButton';
import BuyButtons from '@magalu/ecomm-components/rave/BuyButtons';
import Alert from '@magalu/ecomm-components/components/Alert';
import Benefits from '@magalu/ecomm-components/rave/Benefits';
import ReportButton from '@magalu/ecomm-components/components/ReportButton';
import BuyBoxLabel from '@magalu/ecomm-components/rave/BuyBoxLabel';
import TabProductDetail from '@magalu/ecomm-components/rave/TabProductDetail';
import SellerInfo from '@magalu/ecomm-components/rave/SellerInfo';
import EmptyReviewButton from '@magalu/ecomm-components/rave/EmptyReviewButton';
import ReviewStats from '@magalu/ecomm-components/rave/ReviewStats';
import ReviewListing from '@magalu/ecomm-components/rave/ReviewListing';
import Button from '@magalu/ecomm-components/rave/Button';
import QuestionsList from '@magalu/ecomm-components/rave/QuestionsList';
import QuestionForm from '@magalu/ecomm-components/rave/QuestionForm';
  /**
  * for client modules in typescript, need to use dynamic<T> as any
  * read: https://github.com/vercel/next.js/issues/4515
  */
const QuestionConfirm = dynamic<any>(() =>
  import('@magalu/ecomm-components/rave/QuestionConfirm'), {
  ssr: false
});
  /**
  * for client modules in typescript, need to use dynamic<T> as any
  * read: https://github.com/vercel/next.js/issues/4515
  */
const QuestionFeedback = dynamic<any>(() =>
  import('@magalu/ecomm-components/rave/QuestionFeedback'), {
  ssr: false
});
import Faq from '@magalu/ecomm-components/rave/Faq';
  /**
  * for client modules in typescript, need to use dynamic<T> as any
  * read: https://github.com/vercel/next.js/issues/4515
  */
const BackToTop = dynamic<any>(() =>
  import('@magalu/ecomm-components/components/BackToTop'), {
  ssr: false
});
import Footer from '@magalu/ecomm-components/components/Footer';

// Middlewares
import getIconsVersion from '../../middlewares/getIconsVersion';
import foundError from '@magalu/ecomm-components/rave/middlewares/foundError';
import foundCheck from '@magalu/ecomm-components/rave/middlewares/foundCheck';
import productAttributeMapper from '@magalu/ecomm-components/rave/middlewares/productAttributeMapper';
import redirectFromCanonicalPath from '@magalu/ecomm-components/rave/middlewares/redirectFromCanonicalPath';
import getCartExtensions from '../../middlewares/getCartExtensions';

// Data Source
import PageQuery from './query.json';

// Data Source Configs
import { configs } from '../../datasource/config';
const { cachePolicies = {}, localTypeDefs,  middlewares = [] } = configs  as any ;
import { getParsedQuery, useRouter, useCookies, withRouter, getDeviceInfo } from '@magalu/rave/router';


import {
    getGlobalClassName, routeInfo, getComponents, getHeadProps,
    dataSource, getPageVariables, middlewaresArgs,
    manifest, config as raveConfig, componentsList
  } from './settings';

const Custom404 = dynamic(() => import('../../containers/notfound').catch(() => null));
const CustomError = dynamic(() =>
  import('../../containers/internalError').catch(() => NextDefaultError)
);

const Container = (props : Record<string, any>  = {}) => {
  const { errorCode, errorTitle } = props;
  
  if (errorCode) {
    if (Custom404 && errorCode === 404) {
      return <Custom404 {...props} />;
    }
  
    if (CustomError) {
      return <CustomError {...props} />;
    }
  
    return <NextDefaultError statusCode={errorCode} title={errorTitle} />;
  }
  const route = useRouter();

  const pageProps = { ...props, route, manifest, appConfig: raveConfig };

  const headProps = getHeadProps(pageProps);

  const componentProps = getComponents(pageProps);
  const className = getGlobalClassName(pageProps);
  const gridStyleHtml = {__html: `#product{display: grid;grid-template-areas: 'header header header' '. content .' 'footer footer footer';grid-template-columns: 1fr 100% 1fr;grid-template-rows: auto minmax(100vh, max-content) auto;}`}


  return (
    <>
      <HeadNext><style dangerouslySetInnerHTML={gridStyleHtml}/></HeadNext>
      <div id="product" className={className}>
        <Head {...props} {...headProps} />
          <header { ...{"style":{"gridArea":"header"}} }>
            
            
            
            
            <Preconnect { ...props} {...(componentProps['header-preconnect-0']?.props || {})}/>
            
            
            
            
            
            
            
            <AllIn { ...props} {...(componentProps['header-all-in-1']?.props || {})}/>
            
            
            
            
            
            
            
            <Criteo { ...props} {...(componentProps['header-criteo-2']?.props || {})}/>
            
            
            
            
            
            
            
            <Bing { ...props} {...(componentProps['header-bing-3']?.props || {})}/>
            
            
            
            
            
            
            
            <GoogleTagManager { ...props} {...(componentProps['header-google-tag-manager-4']?.props || {})}/>
            
            
            
            
            
            
            
            <TikTok { ...props} {...(componentProps['header-tik-tok-5']?.props || {})}/>
            
            
            
            
            
            
            
            <RTBHouse { ...props} {...(componentProps['header-rtbhouse-6']?.props || {})}/>
            
            
            
            
            
            
            
            <Stewie { ...props} {...(componentProps['header-stewie-7']?.props || {})}/>
            
            
            
            
            
            
            
            <SpotifyAdAnalytics { ...props} {...(componentProps['header-spotify-ad-analytics-8']?.props || {})}/>
            
            
            
            
            
            
            
            <Hotjar { ...props} {...(componentProps['header-hotjar-9']?.props || {})}/>
            
            
            
            
            
            
            
            <Vwo { ...props} {...(componentProps['header-vwo-10']?.props || {})}/>
            
            
            
            
            
            
            
            <MagaluAds { ...props} {...(componentProps['header-magalu-ads-11']?.props || {})}/>
            
            
            
            
            
            
            
            <Radware { ...props} {...(componentProps['header-radware-12']?.props || {})}/>
            
            
            
            
            <ActivityDetection {...componentProps['header-loader-13'].csr.props}>
            
            
             
            <Loader { ...props} {...(componentProps['header-loader-13']?.props || {})}>
            
            <ActivityDetection {...componentProps['header-loader-13-children-page-loading-0'].csr.props}>
            
            
            <PageLoading { ...props} {...(componentProps['header-loader-13-children-page-loading-0']?.props || {})}/>
            
            </ActivityDetection>
            
             
            </Loader>
            
            </ActivityDetection>
            
            
            <ActivityDetection {...componentProps['header-state-loader-14'].csr.props}>
            
            
             
            <StateLoader { ...props} {...(componentProps['header-state-loader-14']?.props || {})}>
            
            <ActivityDetection {...componentProps['header-state-loader-14-children-page-loading-0'].csr.props}>
            
            
            <PageLoading { ...props} {...(componentProps['header-state-loader-14-children-page-loading-0']?.props || {})}/>
            
            </ActivityDetection>
            
             
            </StateLoader>
            
            </ActivityDetection>
            
            
            
            
            
            <CloseableAdUnit { ...props} {...(componentProps['header-closeable-ad-unit-15']?.props || {})}/>
            
            
            
            
            
            
            
            <Header { ...props} {...(componentProps['header-header-16']?.props || {})}/>
            
            
            
            
            
            
            
            <EventPageViewDispatcher { ...props} {...(componentProps['header-event-page-view-dispatcher-17']?.props || {})}/>
            
            
            
          </header>
          <section { ...{"style":{"gridArea":"content"}} }>
            
            
            
            
            <JsonLD { ...props} {...(componentProps['content-json-ld-0']?.props || {})}/>
            
            
            
            {shouldRender(componentProps['content-json-ld-1']) &&
            
            
            
            
            <JsonLD { ...props} {...(componentProps['content-json-ld-1']?.props || {})}/>
            
            
            
            }
            
            
            
            
            <ZipCodeBar { ...props} {...(componentProps['content-zip-code-bar-2']?.props || {})}/>
            
            
            
            
            
            
            
            <ZipCodeModal { ...props} {...(componentProps['content-zip-code-modal-3']?.props || {})}/>
            
            
            
            
            
            
            
             
            <Row { ...props} {...(componentProps['content-row-4']?.props || {})}>
            
            
            
            
            <Breadcrumb { ...props} {...(componentProps['content-row-4-children-breadcrumb-0']?.props || {})}/>
            
            
            
             
            </Row>
            
            
            
            
            
            
            
            <Showcase { ...props} {...(componentProps['content-showcase-5']?.props || {})}/>
            
            
            
            
            
            
            
             
            <Row { ...props} {...(componentProps['content-row-6']?.props || {})}>
            
            
            
            
            <MediaGallery { ...props} {...(componentProps['content-row-6-children-media-gallery-0']?.props || {})}/>
            
            
            
             
            {shouldRender(componentProps['content-row-6-children-tag-1']) &&
            
            
            
            
            <Tag { ...props} {...(componentProps['content-row-6-children-tag-1']?.props || {})}/>
            
            
            
            }
             
            
            
            
            
            <Heading { ...props} {...(componentProps['content-row-6-children-heading-2']?.props || {})}/>
            
            
            
             
            
            
            
            
             
            <Row { ...props} {...(componentProps['content-row-6-children-row-3']?.props || {})}>
            
            
            
            
            <Rating { ...props} {...(componentProps['content-row-6-children-row-3-children-rating-0']?.props || {})}/>
            
            
            
             
            
            
            
            
             
            <Row { ...props} {...(componentProps['content-row-6-children-row-3-children-row-1']?.props || {})}>
            
            
            
            
            <SocialShare { ...props} {...(componentProps['content-row-6-children-row-3-children-row-1-children-social-share-0']?.props || {})}/>
            
            
            
             
            
            
            
            
            <FavoriteButton { ...props} {...(componentProps['content-row-6-children-row-3-children-row-1-children-favorite-button-1']?.props || {})}/>
            
            
            
             
            </Row>
            
            
            
             
            </Row>
            
            
            
             
            
            
            
            
            <AttributeSelector { ...props} {...(componentProps['content-row-6-children-attribute-selector-4']?.props || {})}/>
            
            
            
             
            {shouldRender(componentProps['content-row-6-children-offer-timer-tag-5']) &&
            
            
            
            
            <OfferTimerTag { ...props} {...(componentProps['content-row-6-children-offer-timer-tag-5']?.props || {})}/>
            
            
            
            }
             
            
            
            
            
            <SellAndDeliverInfo { ...props} {...(componentProps['content-row-6-children-sell-and-deliver-info-6']?.props || {})}/>
            
            
            
             
            
            
            
            
            <SellerDetail { ...props} {...(componentProps['content-row-6-children-seller-detail-7']?.props || {})}/>
            
            
            
             
            </Row>
            
            
            
            {shouldRender(componentProps['content-row-7']) &&
            
            
            
            
             
            <Row { ...props} {...(componentProps['content-row-7']?.props || {})}>
            
            
            
            
            <ProductPrice { ...props} {...(componentProps['content-row-7-children-product-price-0']?.props || {})}/>
            
            
            
             
            
            
            
            
            <ProductTags { ...props} {...(componentProps['content-row-7-children-product-tags-1']?.props || {})}/>
            
            
            
             
            {shouldRender(componentProps['content-row-7-children-import-taxes-2']) &&
            <LazyLoading {...componentProps['content-row-7-children-import-taxes-2'].csr.props}>
            
            
            
            <ImportTaxes { ...props} {...(componentProps['content-row-7-children-import-taxes-2']?.props || {})}/>
            
            
            </LazyLoading>
            }
             
            {shouldRender(componentProps['content-row-7-children-payment-method-cards-3']) &&
            
            
            
            
            <PaymentMethodCards { ...props} {...(componentProps['content-row-7-children-payment-method-cards-3']?.props || {})}/>
            
            
            
            }
             
            {shouldRender(componentProps['content-row-7-children-tab-installments-4']) &&
            
            
            
            
            <TabInstallments { ...props} {...(componentProps['content-row-7-children-tab-installments-4']?.props || {})}/>
            
            
            
            }
             
            </Row>
            
            
            
            }
            {shouldRender(componentProps['content-row-8']) &&
            
            
            
            
             
            <Row { ...props} {...(componentProps['content-row-8']?.props || {})}>
            {shouldRender(componentProps['content-row-8-children-shipping-0']) &&
            <LazyLoading {...componentProps['content-row-8-children-shipping-0'].csr.props}>
            
            
            
            <Shipping { ...props} {...(componentProps['content-row-8-children-shipping-0']?.props || {})}/>
            
            
            </LazyLoading>
            }
             
            {shouldRender(componentProps['content-row-8-children-let-me-know-1']) &&
            
            
            
            
            <LetMeKnow { ...props} {...(componentProps['content-row-8-children-let-me-know-1']?.props || {})}/>
            
            
            
            }
             
            </Row>
            
            
            
            }
            {shouldRender(componentProps['content-row-9']) &&
            
            
            
            
             
            <Row { ...props} {...(componentProps['content-row-9']?.props || {})}>
            
            
            
            
            <ServicesBuyButton { ...props} {...(componentProps['content-row-9-children-services-buy-button-0']?.props || {})}/>
            
            
            
             
            </Row>
            
            
            
            }
            {shouldRender(componentProps['content-row-10']) &&
            
            
            
            
             
            <Row { ...props} {...(componentProps['content-row-10']?.props || {})}>
            
            
            
            
            <BuyButtons { ...props} {...(componentProps['content-row-10-children-buy-buttons-0']?.props || {})}/>
            
            
            
             
            
            
            
            
            <Alert { ...props} {...(componentProps['content-row-10-children-alert-1']?.props || {})}/>
            
            
            
             
            </Row>
            
            
            
            }
            
            
            
            
             
            <Row { ...props} {...(componentProps['content-row-11']?.props || {})}>
            
            
            
            
            <Benefits { ...props} {...(componentProps['content-row-11-children-benefits-0']?.props || {})}/>
            
            
            
             
            {shouldRender(componentProps['content-row-11-children-report-button-1']) &&
            
            
            
            
            <ReportButton { ...props} {...(componentProps['content-row-11-children-report-button-1']?.props || {})}/>
            
            
            
            }
             
            </Row>
            
            
            
            
            
            
            
             
            <Row { ...props} {...(componentProps['content-row-12']?.props || {})}>
            {shouldRender(componentProps['content-row-12-children-buy-box-label-0']) &&
            
            
            
            
            <BuyBoxLabel { ...props} {...(componentProps['content-row-12-children-buy-box-label-0']?.props || {})}/>
            
            
            
            }
             
            
            
            
            
            <TabProductDetail { ...props} {...(componentProps['content-row-12-children-tab-product-detail-1']?.props || {})}/>
            
            
            
             
            </Row>
            
            
            
            {shouldRender(componentProps['content-row-13']) &&
            
            
            
            
             
            <Row { ...props} {...(componentProps['content-row-13']?.props || {})}>
            
            
            
            
            <SellerInfo { ...props} {...(componentProps['content-row-13-children-seller-info-0']?.props || {})}/>
            
            
            
             
            </Row>
            
            
            
            }
            <LazyLoading {...componentProps['content-showcase-14'].csr.props}>
            
            
            
            <Showcase { ...props} {...(componentProps['content-showcase-14']?.props || {})}/>
            
            
            </LazyLoading>
            <LazyLoading {...componentProps['content-ads-showcase-15'].csr.props}>
            
            
            
            <AdsShowcase { ...props} {...(componentProps['content-ads-showcase-15']?.props || {})}/>
            
            
            </LazyLoading>
            {shouldRender(componentProps['content-magalu-ads-showcase-16']) &&
            <LazyLoading {...componentProps['content-magalu-ads-showcase-16'].csr.props}>
            
            
            
            <MagaluAdsShowcase { ...props} {...(componentProps['content-magalu-ads-showcase-16']?.props || {})}/>
            
            
            </LazyLoading>
            }
            <LazyLoading {...componentProps['content-showcase-17'].csr.props}>
            
            
            
            <Showcase { ...props} {...(componentProps['content-showcase-17']?.props || {})}/>
            
            
            </LazyLoading>
            
            
            
            
             
            <Row { ...props} {...(componentProps['content-row-18']?.props || {})}>
            {shouldRender(componentProps['content-row-18-children-empty-review-button-0']) &&
            
            
            
            
            <EmptyReviewButton { ...props} {...(componentProps['content-row-18-children-empty-review-button-0']?.props || {})}/>
            
            
            
            }
             
            {shouldRender(componentProps['content-row-18-children-row-1']) &&
            
            
            
            
             
            <Row { ...props} {...(componentProps['content-row-18-children-row-1']?.props || {})}>
            
            
            
            
            <Heading { ...props} {...(componentProps['content-row-18-children-row-1-children-heading-0']?.props || {})}/>
            
            
            
             
            
            
            
            
            <ReviewStats { ...props} {...(componentProps['content-row-18-children-row-1-children-review-stats-1']?.props || {})}/>
            
            
            
             
            
            
            
            
            <ReviewListing { ...props} {...(componentProps['content-row-18-children-row-1-children-review-listing-2']?.props || {})}/>
            
            
            
             
            
            
            
            
            <Button { ...props} {...(componentProps['content-row-18-children-row-1-children-button-3']?.props || {})}/>
            
            
            
             
            </Row>
            
            
            
            }
             
            </Row>
            
            
            
            <LazyLoading {...componentProps['content-showcase-19'].csr.props}>
            
            
            
            <Showcase { ...props} {...(componentProps['content-showcase-19']?.props || {})}/>
            
            
            </LazyLoading>
            {shouldRender(componentProps['content-row-20']) &&
            
            
            
            
             
            <Row { ...props} {...(componentProps['content-row-20']?.props || {})}>
            
            
            
            
            <QuestionsList { ...props} {...(componentProps['content-row-20-children-questions-list-0']?.props || {})}/>
            
            
            
             
            
            
            
            
            <QuestionForm { ...props} {...(componentProps['content-row-20-children-question-form-1']?.props || {})}/>
            
            
            
             
            
            <ActivityDetection {...componentProps['content-row-20-children-question-confirm-2'].csr.props}>
            
            
            <QuestionConfirm { ...props} {...(componentProps['content-row-20-children-question-confirm-2']?.props || {})}/>
            
            </ActivityDetection>
            
             
            
            <ActivityDetection {...componentProps['content-row-20-children-question-feedback-3'].csr.props}>
            
            
            <QuestionFeedback { ...props} {...(componentProps['content-row-20-children-question-feedback-3']?.props || {})}/>
            
            </ActivityDetection>
            
             
            {shouldRender(componentProps['content-row-20-children-button-4']) &&
            
            
            
            
            <Button { ...props} {...(componentProps['content-row-20-children-button-4']?.props || {})}/>
            
            
            
            }
             
            </Row>
            
            
            
            }
            <LazyLoading {...componentProps['content-showcase-21'].csr.props}>
            
            
            
            <Showcase { ...props} {...(componentProps['content-showcase-21']?.props || {})}/>
            
            
            </LazyLoading>
            {shouldRender(componentProps['content-row-22']) &&
            
            
            
            
             
            <Row { ...props} {...(componentProps['content-row-22']?.props || {})}>
            {shouldRender(componentProps['content-row-22-children-faq-0']) &&
            
            
            
            
            <Faq { ...props} {...(componentProps['content-row-22-children-faq-0']?.props || {})}/>
            
            
            
            }
             
            </Row>
            
            
            
            }
            
            
            
            
            <LuContent { ...props} {...(componentProps['content-lu-content-23']?.props || {})}/>
            
            
            
            {shouldRender(componentProps['content-term-list-24']) &&
            
            
            
            
            <TermList { ...props} {...(componentProps['content-term-list-24']?.props || {})}/>
            
            
            
            }
            
            <ActivityDetection {...componentProps['content-gatekeeper-25'].csr.props}>
            
            
            <Gatekeeper { ...props} {...(componentProps['content-gatekeeper-25']?.props || {})}/>
            
            </ActivityDetection>
            
          </section>
          <footer { ...{"style":{"gridArea":"footer"}} }>
            
            <ActivityDetection {...componentProps['footer-back-to-top-0'].csr.props}>
            
            
            <BackToTop { ...props} {...(componentProps['footer-back-to-top-0']?.props || {})}/>
            
            </ActivityDetection>
            
            
            
            
            
            <Footer { ...props} {...(componentProps['footer-footer-1']?.props || {})}/>
            
            
            
          </footer>
      </div>
    </>
  )
};

export default withRouter(Container, {...routeInfo, manifest });


const fetchData = async (ctx, props) => {
  const { server } = useServerEnv();
  const { parser } = routeInfo;
  const cookieUtils = useCookies(ctx, parser?.cookie);
  const route = {
      query: getParsedQuery(ctx?.query, routeInfo),
      cookie: cookieUtils.getCookies(),
      deviceInfo: props?.route?.deviceInfo || {},
  };
  
  const pageVariables = getPageVariables({ route });
  
  const ApolloClient = client({
    config: {
      ...(dataSource?.graphql || {}),
      ...(server?.datasource?.graphql || {}),
    },
    cachePolicies,
    middlewares,
    localTypeDefs,
  });
  
  const { data, errors = null } = await ApolloClient.query({ query: PageQuery, variables: pageVariables });
  props.data = data;
  props.errors = errors
  return;
};

const serverSideMiddlewares = [
  { id: 'fetchData', functionMiddleware: fetchData },
  { id: 'getIconsVersion', functionMiddleware: getIconsVersion },
  { id: 'foundError', functionMiddleware: foundError },
  { id: 'foundCheck', functionMiddleware: foundCheck },
  { id: 'productAttributeMapper', functionMiddleware: productAttributeMapper },
  { id: 'redirectFromCanonicalPath', functionMiddleware: redirectFromCanonicalPath },
  { id: 'getCartExtensions', functionMiddleware: getCartExtensions },
];

export const getServerSideProps = async (ctx) => {
  try {
    const props = {
      ctx: {},
      data: {},
      manifest: {},
      route: {},
      isServerRequest: true
    };

    const isSSR = !ctx.req?.url?.includes('_next/data');
    props.ctx = {
      req: {
        url: ctx.req?.url,
        method: ctx.req?.method,
        headers: parseHeaders(ctx.req?.headers, {
          forwardHeaders: routeInfo.forwardHeaders,
          forwardCookies: routeInfo.forwardCookies,
          isSSR,
        }),
      }
    };

    props.manifest = manifest;
    props.appConfig = raveConfig || {};
    props.route = {
      id: routeInfo.id,
      name: routeInfo.name,
      pathPattern: routeInfo.pathPattern,
      pathAliases: routeInfo.pathAliases,
      renderMethod: routeInfo.renderMethod,
      deviceInfo: getDeviceInfo(ctx.req?.headers, manifest),
    };
    props.isServerRequest = isSSR;
    props.componentsList = componentsList;

    await middlewareServerExecutor(
      serverSideMiddlewares,
      ctx,
      props,
      middlewaresArgs
    );

    //ref: https://nextjs.org/docs/pages/api-reference/functions/get-server-side-props#notfound
    if (props.notFound) {
      return { notFound: true };
    }
    return { props };
  } catch (e) {
    if (ctx?.res) ctx.res.statusCode = 500;
    return { props: { errorCode: 500, errorTitle: e.message } };
  }
};
